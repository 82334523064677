import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-affectation-vehicules',
  templateUrl: './affectation-vehicules.component.html',
  styleUrls: ['./affectation-vehicules.component.scss']
})
export class AffectationVehiculesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
